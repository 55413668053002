import Vue from 'vue'

const elements = {}
const instances = {}

export function setLoadingStatus(id, status) {
    const oldInstance = instances[id]
    if (status) {
        if (!oldInstance) {
            instances[id] = Vue.prototype.$loading({
                target: elements[id],
                background: 'transparent'
            })
        }
    } else if (oldInstance) {
        delete instances[id]
        setTimeout(() => {
            oldInstance.close()
        }, 150)
    }
}

export const installVIdentLoading = (Vue) => {
    Vue.directive('ident-loading', {
        bind: (el, binding, vnode) => {
            const id = binding.value
            if (!id) {
                return
            }

            elements[id] = el
        },
        unbind: (el, binding) => {
            const id = binding.value
            if (!id) {
                return
            }

            delete elements[id]
            delete instances[id]
        }
    })
}